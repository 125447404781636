.question-container {
  display: block;
  margin-bottom: 53px;
}
.accartion {
  padding: 2.5rem 2rem;
  display: grid;
  gap: 1rem 2rem;
}
.question {
  margin-bottom: -2rem;
}

.btn {
  width: 50px;
  background: transparent;
  border-color: transparent;
  display: flex;
  border: none;
  cursor: pointer;
  outline: none;
  align-self: center;
  font-size: 2rem;
  margin-left: auto;
  background-color: #fdc600;
}

@media (min-width: 768px) {
  .question-containe {
    display: block;
  }
}
