.navbar-brand {
  height: 13rem;
  margin-top: -3rem;
  margin-bottom: -4rem;
  margin-right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-item-text {
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #e9ecef;
  background-position: 50%;
  margin-top: -27px;
}

@media (max-width: 480px) {
  .navbar-brand {
    margin-left: -50px;
    margin-right: 0px;
  }
  .search input[type="text"]:focus ~ .symbol::before {
    width: 0%;
  }

  .search .symbol:before {
    width: 0%;
  }
}

.header-title p {
  margin-left: -9rem;
  color: #fff;
  margin-top: -30rem;
}

.header-title-per {
  font-weight: bold;
  color: #fdc600;
  margin-top: 3rem;
}

.navbar-default .navbar-nav > li > a {
  padding: 1rem;
}

/* Search input  */

.search {
  margin-top: 3px;
  --background: #ffffff;
  --text-color: #414856;
  --primary-color: #fdc600;
  --border-radius: 10px;
  --width: 190px;
  --height: 44px;
  background: var(--background);
  width: auto;
  height: var(--height);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 30px rgba(65, 72, 86, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}

.search input[type="text"] {
  position: relative;
  width: 55px;
  height: var(--height);
  font: 400 16px "Varela Round", sans-serif;
  color: var(--text-color);
  border: 0;
  box-sizing: border-box;
  outline: none;
  padding: 0 0 0 40px;
  transition: width 0.6s ease;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}

.search input[type="text"]:focus {
  z-index: 0;
  opacity: 1;
  width: var(--width);
}

.search input[type="text"]:focus ~ .symbol::before {
  width: 0%;
}

.search input[type="text"]:focus ~ .symbol:after {
  -webkit-clip-path: inset(0% 0% 0% 100%);
  clip-path: inset(0% 0% 0% 100%);
  transition: -webkit-clip-path 0.04s linear 0.105s;
  transition: clip-path 0.04s linear 0.105s;
  transition: clip-path 0.04s linear 0.105s,
    -webkit-clip-path 0.04s linear 0.105s;
}

.search input[type="text"]:focus ~ .symbol .cloud {
  top: -30px;
  left: -30px;
  transform: translate(0, 0);
  transition: all 0.6s ease;
}

.search input[type="text"]:focus ~ .symbol .lens {
  top: 20px;
  left: 15px;
  transform: translate(0, 0);
  fill: var(--primary-color);
  transition: top 0.5s ease 0.1s, left 0.5s ease 0.1s, fill 0.3s ease;
}

.search .symbol {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search .symbol:before {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  z-index: -1;
  transition: width 0.6s ease;
}

.search .symbol:after {
  content: "";
  position: absolute;
  top: 21px;
  left: 21px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary-color);
  z-index: 1;
  -webkit-clip-path: inset(0% 0% 0% 0%);
  clip-path: inset(0% 0% 0% 0%);
  transition: -webkit-clip-path 0.04s linear 0.225s;
  transition: clip-path 0.04s linear 0.225s;
  transition: clip-path 0.04s linear 0.225s,
    -webkit-clip-path 0.04s linear 0.225s;
}

.search .symbol .cloud,
.search .symbol .lens {
  position: absolute;
  fill: #fff;
  stroke: none;
  top: 50%;
  left: 50%;
}

.search .symbol .cloud {
  width: 35px;
  height: 32px;
  transform: translate(-50%, -60%);
  transition: all 0.6s ease;
}

.search .symbol .lens {
  fill: #fff;
  width: 16px;
  height: 16px;
  z-index: 2;
  top: 24px;
  left: 24px;
  transition: top 0.3s ease, left 0.3s ease, fill 0.2s ease 0.2s;
}

.socials {
  position: fixed;
  display: block;
  left: 20px;
  bottom: 20px;
}

.socials > a {
  display: block;
  width: 30px;
  opacity: var(--opacity, 0.2);
  transform: scale(var(--scale, 0.8));
  transition: transform 0.3s cubic-bezier(0.38, -0.12, 0.24, 1.91);
}

.socials > a:hover {
  --scale: 1;
}

/* Search tugadi */

.yana {
  margin-left: 15rem;
}

.header-title-simple {
  color: #fff;
}

.brand {
  font-size: 10px;
}

.count-img {
  margin-top: 1.8rem;
  height: 6rem;
  width: auto;
}

.sale-img {
  margin-top: 0.6rem;
  height: 5.3rem;
}

.pulse {
  height: 10rem;
  /* border-radius: 50%; */
}

.btn-grad {
  padding: 1px 5px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #000;
  box-shadow: 1px 0 2px #000;
  border-radius: 5px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fdc703;
  text-decoration: none;
}

.card-image1 img {
  height: 220px;
  object-fit: cover;
}

.light-slide-item1 {
  position: relative;
  overflow: hidden;
}

.main-logo {
  margin-right: 10px;
  margin-left: 30px;
  width: 90px;
  border-radius: 100%;
}

@media (min-width: 768px) {
  /* .api-area {
    margin-left: 320px;
  } */
  .container_city .box1 {
    height: 500px;
  }
  .question-container {
    display: flex;
  }
  .cart_container {
    display: flex;
  }

  .cart2 {
    margin-left: 400px;
  }
  .panel.sidebar-menu {
    display: block;
  }
  .button_payme {
    margin-left: 71px;
    width: 45%;
  }
}

#header.header-scrolled {
  transition: all 0.5s;
  background-color: rgba(34, 34, 34, 0.9);
}

#header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  margin: 0;
}

.main-menu {
  padding-bottom: 20px;
  background: rgba(255, 255, 255, 0.15);
  padding-left: 15px;
  padding-right: 15px;
  background: none;
}

@media (max-width: 480px) {
  .main-menu {
    padding-bottom: 0px;
    padding-top: 0px;
    background: rgba(255, 255, 255, 0.15);
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    margin-left: -15px;
  }
  .form_flex {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .payme {
    margin-top: 10px;
    width: 80px !important;
    margin-left: 3px;
  }
  .btn-visa {
    margin-left: 75px !important;
  }
  .button_payme {
    margin-left: 71px;
    width: 45%;
  }
}

.count {
  text-align: center;
}

.form_flex {
  width: auto;
}

.flag-img {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 500px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.9) !important;
}

.page-head-content h2 {
  font-weight: 500;
}

.tel-number {
  color: #000 !important;
}

.footer-api-name {
  margin-left: 5px;
}

.carousel .thumb {
  height: 50px;
  margin-left: 10px;
}

.carousel .thumbs {
  margin-left: 115px;
}
