/* CSS */
.btn-form {
  align-items: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  color: #1a202c;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #fff;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 25px;
  font-weight: 700;
  justify-content: center;
  line-height: 10px;
  overflow-wrap: break-word;
  text-decoration: none;
  width: auto;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* CSS */
.button_payme {
  background-color: #03cccd;
  border-width: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1em;
  margin-top: 15px;
  opacity: 1;
  outline: 0;
  /* padding: 0.4em 1.8em; */
  width: 43%;
  height: 38px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
    background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  margin-left: 106px;
  border-radius: 2.5px;
}

.button_payme:before {
  animation: opacityFallbackOut 0.5s step-end forwards;
  backface-visibility: hidden;
  background-color: rgb(164, 164, 164);
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

.button_payme:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}
.button_payme:after {
  background-color: #f5c65d;
}

.button_payme span {
  z-index: 1;
  position: relative;
}

.texta {
  color: #fff;
}

.form-flex {
  background-color: #f5c65d;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.form-flex .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-flex button {
  background-color: #fff;
  padding: 8px 20px;
  align-items: center;
}
.form-flex h2 {
  padding: 0 30px;
}
.payme {
  width: 85px;
}

.hidden {
  cursor: not-allowed;
}

.visa-btn {
  margin-top: 15px;
  display: flex;
}

.visa-img {
  width: 85px;
  margin-left: -26px;
}

.btn-visa {
  border: none;
  outline: none;
  text-transform: uppercase;
  background-color: #1a39ac;
  color: #fff;
  border-radius: 5px;
  margin-left: 117px;
  width: 85%;
  height: 38px;
}
